import { IStyle } from "fela";
import React, { RefObject } from "react";
import { Box } from "../box/box";

type ReactInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface InputProps extends Omit<ReactInputProps, "style" | "ref"> {
    style?: IStyle;
    forwardedRef?: RefObject<HTMLInputElement>;
}

const Input = (props: InputProps) => <Box<InputProps, HTMLInputElement> as="input" {...props} />;

export default Input;
