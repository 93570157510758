import { createContext } from "react";

interface ErrorContextType {
    error: string | undefined;
    setError(error: string | undefined): void;
}

export const ErrorContext = createContext<ErrorContextType>({
    error: undefined,
    setError: (error: string | undefined) => {},
});

export const ErrorProvider = ErrorContext.Provider;
