import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { KBApi } from "../api";
import { Article } from "../components/ArticleHighlights";
import { ArticleList } from "../components/ArticleList";

export const ViewSearch = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState<Article[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [params] = useSearchParams();
    const qParams = useParams();

    const performSearch = async (query: string | null) => {
        setIsLoading(true);
        if (query === null) {
            setResults([]);
            setErrorMessage("No query provided");
            setIsLoading(false);
            return;
        }
        try {
            const response = await KBApi.searchArticlesBy(qParams.hash as string, encodeURIComponent(query));
            setResults(response.data);
            setIsLoading(false);
            setErrorMessage("");
        } catch (err) {
            setResults([]);
            setErrorMessage("Encountered an error, please try again");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        performSearch(params.get("q"));
    }, [params]);

    if (isLoading) {
        return <div>Loading..</div>;
    }

    if (errorMessage !== "") {
        return <div>{errorMessage}</div>;
    }

    return (
        <div>
            <br />
            <h2 className="text-2xl  mb-5">
                {t("search.results")} ({results.length})
            </h2>
            <h3 className="mb-5">
                {t("search.query")}:<em className="bg-gray-200 text-[#DF1515] p-1 ml-1">{params.get("q")}</em>
            </h3>
            <ArticleList articles={results} isSearch={true} />
        </div>
    );
};
