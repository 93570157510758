import { IStyle } from "fela";
import { Theme } from "../../../types";

export const button: (theme: Theme) => IStyle = (theme) => ({
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    borderWidth: "0px",
    backgroundColor: theme?.button?.style?.backgroundColor ?? "#55b6ed",
    borderRadius: "6px",
    cursor: "pointer",
    fontFamily: theme?.button?.font?.family ?? "initial",
    fontStyle: theme?.button?.style?.fontStyle ?? "initial",
    fontSize: theme?.button?.style?.fontSize ?? "initial",
    color: theme?.button?.style?.color ?? "initial",
});
