import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import xhr from "i18next-xhr-backend";
import { getDefaultLanguage } from "./defaultLanguage";

class Internationalization {
    static init = () => {
        i18n.use(xhr)
            .use(initReactI18next)
            .init({
                // have a common namespace used around the full app
                ns: ["translations"],
                defaultNS: "translations",
                lng: getDefaultLanguage(),
                fallbackLng: new URLSearchParams(window.location.search).get("lang") || "fi",
                debug: true,
                interpolation: {
                    escapeValue: false, // not needed for react!!
                },
            });
    };
}

export default Internationalization;
