import { createContext } from "react";
import { FrontpageResponseData } from "../types";

interface ViewContextType {
    data: FrontpageResponseData | null;
    setData(data: FrontpageResponseData): void;
}

export const ViewContext = createContext<ViewContextType>({
    data: null,
    setData: (data: FrontpageResponseData) => {},
});

export const ViewProvider = ViewContext.Provider;
