import ApiConfig from "../ApiConfig";
import { ArticleParams } from "../views/ViewArticle";

const cIdSquareBracketsRegex = new RegExp(/\[cid(?:(?:%3A)|(?::))(.*?)\]/gim);
const cIdImgSourceRegex = new RegExp(/<img(.*?)src=["'](?:cid)(?:(?:%3A)|(?::))(.*?)['"]([^>]*)/gim);

/*
 * Meant for to transform [cid:image.jpg@123-123-123] into <img src..
 */
const getImageSource = (url: string) => {
    return `<img src="${url}" />`;
};

export const parseArticle = (content: string, params: ArticleParams): string => {
    // transform cIds to
    return content
        .replaceAll(cIdSquareBracketsRegex, (substring: string, ...args: any[]) => {
            // [cid:blabla.png@123-123]
            if (!params.hash || !params.id) {
                return substring;
            }
            return getImageSource(getResourceUrl(params.hash, params.id, args[0].substring(4)));
        })
        .replaceAll(cIdImgSourceRegex, (substring: string, ...args: any[]) => {
            // <img src="cid:blabla.png@123-123" />
            console.log("Args for sources", args);
            if (!params.hash || !params.id) {
                return substring;
            }
            const startOfCid = substring.indexOf(args[1]);
            substring = substring.slice(0, startOfCid - 4) + substring.slice(startOfCid, substring.length);
            const newUrl = getResourceUrl(params.hash, params.id, args[1]);
            return substring.replace(args[1], newUrl);
        });
};

const getResourceUrl = (viewId: string, articleId: string, cId: string) => {
    const baseUrl = ApiConfig.getConfig().API_URL;
    return `${baseUrl}/view/${viewId}/articles/${articleId}/images/${cId}`;
};
