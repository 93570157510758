import React from "react";
import { ArticleList } from "../components/ArticleList";
import { CategoriesList } from "../components/CategoriesList";
import { ArticleHighlights } from "../components/ArticleHighlights";
import { useContext } from "react";
import { ViewContext } from "../context/ViewContext";
import { IsLoadingContext } from "../context/IsLoadingContext";
import { ErrorContext } from "../context/ErrorContext";
import { useParams } from "react-router";
import { KBApi } from "../api";
import { AxiosResponse } from "axios";
import { FrontpageResponseData } from "../types";
import { useTranslation } from "react-i18next";

export const FrontPage = () => {
    const { isLoading, setIsLoading } = useContext(IsLoadingContext);
    const { error, setError } = useContext(ErrorContext);
    const { data, setData } = useContext(ViewContext);
    const { t } = useTranslation();
    const params = useParams();

    // FIXME: copy/paste solution for now, looks to be good variant to try RTK query
    const fetchFrontpage = async () => {
        if (!params.hash) {
            setError("Unknown page.");
            return;
        }
        try {
            const response: AxiosResponse<FrontpageResponseData> = await KBApi.getFrontpage(params.hash as string);
            setData(response.data);
            setError(undefined);
        } catch (error) {
            console.error("Error fetching Frontpage", error);
            setError("Error fetching Frontpage");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <p>{t("loading")}</p>}
            {!!error && <button onClick={fetchFrontpage}>{t("try_again")}</button>}
            {!isLoading && !!data && (
                <React.Fragment>
                    <div className="flex flex-row mt-10">
                        <ArticleList articles={data.frontpage} />
                        <ArticleHighlights articles={data.highlights} params={params} />
                    </div>
                    <CategoriesList categories={data.categories} />
                </React.Fragment>
            )}
        </>
    );
};
