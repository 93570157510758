import * as React from "react";
import { useState } from "react";
import { ThemeProvider } from "react-fela";
import { Outlet, useRoutes } from "react-router-dom";
import { Main } from "./components/Main";
import { ErrorProvider } from "./context/ErrorContext";
import { IsLoadingProvider } from "./context/IsLoadingContext";
import { ViewProvider } from "./context/ViewContext";
import { FelaProvider } from "./fela/fela-fela-provider";
import { getFelaRenderer } from "./fela/get-fela-renderer";
import { FrontpageResponseData } from "./types";
import { FrontPage } from "./views/FrontPage";
import { ViewArticle } from "./views/ViewArticle";
import { ViewCategory } from "./views/ViewCategory";
import { ViewSearch } from "./views/ViewSearch";

const renderer = getFelaRenderer();

export const App = () => {
    const [data, setData] = useState<FrontpageResponseData | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const routes = [
        {
            path: "/v",
            element: <Outlet />,
            children: [
                {
                    path: ":hash",
                    element: <Main />,
                    children: [
                        {
                            path: "/v/:hash/",
                            element: <FrontPage />,
                        },
                        {
                            path: "/v/:hash/c",
                            element: <ViewCategory />,
                            children: [{ path: "/v/:hash/c/:id", element: <ViewCategory /> }],
                        },
                        {
                            path: "/v/:hash/a",
                            element: <ViewArticle />,
                            children: [{ path: "/v/:hash/a/:id", element: <ViewArticle /> }],
                        },
                        {
                            path: "/v/:hash/s",
                            element: <ViewSearch />,
                        },
                    ],
                },
            ],
        },
    ];

    const routeElement = useRoutes(routes);

    return (
        <FelaProvider renderer={renderer}>
            <IsLoadingProvider value={{ isLoading, setIsLoading }}>
                <ErrorProvider value={{ error, setError }}>
                    <ViewProvider value={{ data, setData }}>
                        <ThemeProvider theme={data?.settings?.theme ?? {}}>{routeElement}</ThemeProvider>
                    </ViewProvider>
                </ErrorProvider>
            </IsLoadingProvider>
        </FelaProvider>
    );
};
