import namedKeys from "fela-plugin-named-keys";

const screenXxs = 320;
const screenXxsMin = screenXxs;
const screenXs = 480;
const screenXsMin = screenXs;
const screenSm = 768;
const screenSmMin = screenSm;
const screenMd = 992;
const screenMdMin = screenMd;
const screenLg = 1260;
const screenLgMin = screenLg;
const screenXL = 1281;
const screenXLMin = screenXL;

const screenXxsMax = screenXs - 1;
const screenXsMax = screenSmMin - 1;
const screenSmMax = screenMdMin - 1;
const screenMdMax = screenLgMin - 1;
const screenXLMax = screenXLMin - 1;

export const screenSizes = {
    screenXxs,
    screenXxsMin,
    screenXs,
    screenXsMin,
    screenSm,
    screenSmMin,
    screenMd,
    screenMdMin,
    screenLg,
    screenLgMin,
    screenXxsMax,
    screenXsMax,
    screenSmMax,
    screenMdMax,
    screenXLMin,
    screenXLMax,
};

const namedKeysPlugin = namedKeys({
    minWidthXxs: `@media (min-width: ${screenSizes.screenXxs}px)`,
    maxWidthXxs: `@media (max-width: ${screenSizes.screenXxs}px)`,
    minWidthXxsMin: `@media (min-width: ${screenSizes.screenXxsMin}px)`,
    maxWidthXxsMin: `@media (max-width: ${screenSizes.screenXxsMin}px)`,
    minWidthXxsMax: `@media (min-width: ${screenSizes.screenXxsMax}px)`,
    maxWidthXxsMax: `@media (max-width: ${screenSizes.screenXxsMax}px)`,
    minWidthXs: `@media (min-width: ${screenSizes.screenXs}px)`,
    maxWidthXs: `@media (max-width: ${screenSizes.screenXs}px)`,
    minWidthXsMin: `@media (min-width: ${screenSizes.screenXsMin}px)`,
    maxWidthXsMin: `@media (max-width: ${screenSizes.screenXsMin}px)`,
    minWidthXsMax: `@media (min-width: ${screenSizes.screenXsMax}px)`,
    maxWidthXsMax: `@media (max-width: ${screenSizes.screenXsMax}px)`,
    minWidthSm: `@media (min-width: ${screenSizes.screenSm}px)`,
    maxWidthSm: `@media (max-width: ${screenSizes.screenSm}px)`,
    minWidthSmMin: `@media (min-width: ${screenSizes.screenSmMin}px)`,
    maxWidthSmMin: `@media (max-width: ${screenSizes.screenSmMin}px)`,
    minWidthSmMax: `@media (min-width: ${screenSizes.screenSmMax}px)`,
    maxWidthSmMax: `@media (max-width: ${screenSizes.screenSmMax}px)`,
    minWidthMd: `@media (min-width: ${screenSizes.screenMd}px)`,
    maxWidthMd: `@media (max-width: ${screenSizes.screenMd}px)`,
    minWidthMdMin: `@media (min-width: ${screenSizes.screenMdMin}px)`,
    maxWidthMdMin: `@media (max-width: ${screenSizes.screenMdMin}px)`,
    minWidthMdMax: `@media (min-width: ${screenSizes.screenMdMax}px)`,
    maxWidthMdMax: `@media (max-width: ${screenSizes.screenMdMax}px)`,
    minWidthLg: `@media (min-width: ${screenSizes.screenLg}px)`,
    maxWidthLg: `@media (max-width: ${screenSizes.screenLg}px)`,
    minWidthLgMin: `@media (min-width: ${screenSizes.screenLgMin}px)`,
    maxWidthLgMin: `@media (max-width: ${screenSizes.screenLgMin}px)`,
    minWidthXLMin: `@media (min-width: ${screenSizes.screenXLMin}px)`,
    maxWidthXLMin: `@media (max-width: ${screenSizes.screenXLMin}px)`,
});

export default namedKeysPlugin;
