import { IStyle } from "fela";

interface SearchStyles {
    container: IStyle;
    button: IStyle;
    inputWrapper: IStyle;
    input: IStyle;
}

export const searchStyles: SearchStyles = {
    container: {
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        height: "40px",
        justifyContent: "center",
        lineHeight: "24px",
        marginTop: "20px",
        tabSize: "4",
        textSizeAdjust: "100%",
    },
    button: {
        alignItems: "flex-start",
        appearance: "button",
        backgroundColor: "rgba(0, 0, 0, 0)",
        backgroundImage: "none",
        boxSizing: "border-box",
        borderWidth: "0px",
        cursor: "pointer",
        display: "block",
        fontSize: "30px",
        fontStretch: "100%",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginRight: "16px",
        marginTop: "12px",
        paddingLeft: "20px",
        position: "absolute",
        right: "0px",
        tabSize: "4",
        textAlign: "center",
        textIndent: "0px",
        textRendering: "auto",
        textShadow: "none",
        textSizeAdjust: "100%",
        textTransform: "none",
        top: "0px",
        width: "36px",
        wordSpacing: "0px",
        writingMode: "horizontal-tb",
        "> svg": {
            borderWidth: "0px",
            height: "1rem",
            width: "1rem",
            fill: "currentColor",
            display: "block",
            verticalAlign: "middle",
        },
    },
    inputWrapper: {
        borderRadius: "8px",
        borderWidth: "0px",
        boxSizing: "border-box",
        color: "rgb(75, 85, 99)",
        display: "block",
        lineHeight: "24px",
        position: "relative",
        tabSize: "4",
        textSizeAdjust: "100%",
        width: "33%",
    },
    input: {
        appearance: "textfield",
        backgroundColor: "rgb(255, 255, 255)",
        borderWidth: "0px",
        borderRadius: "20px",
        norderWidth: "0px",
        color: "rgb(75, 85, 99)",
        cursor: "text",
        display: "inline-block",
        fontSize: "18px",
        fontStretch: "100%",
        fontStyle: "normal",
        fontWeight: "400",
        height: "40px",
        letterSpacing: "normal",
        lineHeight: "28px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginTop: "0px",
        outlineOffset: "-2px",
        paddingBottom: "0px",
        paddingLeft: "20px",
        paddingRight: "40px",
        paddingTop: "0px",
        tabSize: "4",
        textAlign: "start",
        textIndent: "0px",
        textRendering: "auto",
        textShadow: "none",
        textSizeAdjust: "100%",
        textTransform: "none",
        width: "100%",
        wordSpacing: "0px",
        writingMode: "horizontal-tb",
        ":focus-visible": {
            outline: "none",
            borderImage: "none",
        },
    },
};
