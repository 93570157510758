import { IStyle } from "fela";
import React, { RefObject } from "react";
import { useFela } from "react-fela";
import { ReactDivProps } from "../../../types";

type BoxProps<T = ReactDivProps, R = HTMLElement> = {
    as?: HTMLElement["tagName"];
    style?: IStyle;
    forwardedRef?: RefObject<R>;
    className?: string;
} & T;

export function Box<T, R = HTMLElement>({
    as = "div",
    style,
    forwardedRef,
    className: originalClassName,
    ...props
}: BoxProps<T, R>) {
    const { css } = useFela();
    const className = `${originalClassName ? `${originalClassName} ` : ""}${css({
        boxSizing: "border-box",
        ...style,
    })}`;
    const element = React.createElement(as, {
        className,
        ref: forwardedRef,
        ...props,
    });

    return element;
}
