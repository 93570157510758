import { createContext } from "react";

interface IsLoadingContextType {
    isLoading: boolean;
    setIsLoading(isLoading: boolean): void;
}

export const IsLoadingContext = createContext<IsLoadingContextType>({
    isLoading: false,
    setIsLoading: (isLoading: boolean) => {},
});

export const IsLoadingProvider = IsLoadingContext.Provider;
