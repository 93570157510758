import React, { useEffect, useReducer } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { KBApi } from "../api";
import { ArticleList, ShortArticle } from "../components/ArticleList";
import { Box } from "../components/generic/box/box";
import NavButton from "../components/generic/nav-button/NavButton";
import { parseArticle } from "../utils/parsing";

interface Article {
    id: string;
    title: string;
    content: string;
    parents: ShortArticle[];
    children: ShortArticle[];
}

function reducer(state: State, action: State) {
    return action;
}

const initState = { status: "loading" };
type LoadingState = { status: "loading" };
type ErrorState = { status: "error"; error: string };
type SuccessState = { status: "success"; data: Article };
type State = LoadingState | ErrorState | SuccessState;
export interface ArticleParams {
    hash?: string;
    id?: string;
}

async function fetchArticle(params: ArticleParams, dispatch: React.Dispatch<State>, t: TFunction) {
    if (!params.id || !params.hash) {
        return dispatch({ status: "error", error: t("resource_nonexisting") });
    }
    return KBApi.getArticleById(params.hash, params.id)
        .then((response) => {
            dispatch({ status: "success", data: response.data as Article });
        })
        .catch((error) => {
            dispatch({ status: "error", error: t("download_failed") });
        });
}

const renderSuccess = (state: SuccessState, t: TFunction, params: ArticleParams) => {
    const anyRelatedArticles = state.data.children.length > 0 || state.data.parents.length > 0;
    const classNameForRelatedArticles = anyRelatedArticles ? "col-span-3" : "col-span-0 hidden";

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 mb-5">
                {/* Breadcrumbs should go here */}
                <NavButton to="../">{t("go_back")}</NavButton>
            </div>
            <div className={classNameForRelatedArticles}>
                {state.data.parents.length > 0 && (
                    <>
                        <h3 className="pb-2 text-lg">{t("articles.parent")}</h3>
                        <ArticleList articles={state.data.parents} />
                    </>
                )}
                {state.data.children.length > 0 && (
                    <>
                        <h3 className="pb-2 text-lg">{t("articles.child")}</h3>
                        <ArticleList articles={state.data.children} />
                    </>
                )}
            </div>
            <div className="col-span-9">
                <Box as="h2" style={{ fontWeight: "bold" }} className="pb-2 text-xl font-bold">
                    {state.data.title}
                </Box>
                <div
                    dangerouslySetInnerHTML={{
                        __html: parseArticle(state.data.content, params),
                    }}
                />
            </div>
        </div>
    );
};

const renderError = (state: ErrorState, dispatch: React.Dispatch<State>, t: TFunction) => {
    return (
        <div>
            <h2>{t("error")}</h2>
            <p>{t("description")}:</p>
            <code>{state.error}</code>
            <button
                className="bg-grey-500 br-mg"
                onClick={() => {
                    dispatch({ status: "loading" });
                }}
            >
                {t("try_again")}
            </button>
        </div>
    );
};

export const ViewArticle = () => {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initState as any);
    const params = useParams();

    useEffect(() => {
        fetchArticle({ hash: params.hash, id: params.id }, dispatch, t);
    }, [params.id]);

    switch (state.status) {
        case "success": {
            return renderSuccess(state as SuccessState, t, params);
        }
        case "loading": {
            return <p>{t("loading")}</p>;
        }
        default:
            return renderError(state as ErrorState, dispatch, t);
    }
};
