import axios from "axios";

interface ApiConfigurationParameters {
    API_URL: string;
}

export default class ApiConfig {
    static version: string = "";
    static isVersionCheckPostponed: boolean = false;

    static config: ApiConfigurationParameters = {
        API_URL: "",
    };

    static fetchConfig() {
        return axios.get("/config/config.json");
    }

    static setConfig(incomingConfig: ApiConfigurationParameters) {
        this.config = {
            ...this.config,
            ...incomingConfig,
        };
    }

    static getConfig() {
        return this.config;
    }
}
