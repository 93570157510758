import { AxiosResponse } from "axios";
import React, { useContext, useEffect } from "react";
import { useFela } from "react-fela";
import { Outlet, useParams } from "react-router";
import { KBApi } from "../api";
import { ErrorContext } from "../context/ErrorContext";
import { IsLoadingContext } from "../context/IsLoadingContext";
import { ViewContext } from "../context/ViewContext";
import { FrontpageResponseData } from "../types";
import { defaultStyles } from "../utils/constants";
import { Box } from "./generic/box/box";
import { Col } from "./generic/grid/col";
import { Row } from "./generic/grid/row";
import { Header } from "./Header";

export const Main = () => {
    const params = useParams();
    const { renderer } = useFela();
    const { setData, data } = useContext(ViewContext);
    const theme = data?.settings?.theme;
    const { setIsLoading } = useContext(IsLoadingContext);
    const { setError } = useContext(ErrorContext);

    const fetchFrontpage = async () => {
        if (!params.hash) {
            setError("Unknown page.");
            return;
        }
        try {
            const response: AxiosResponse<FrontpageResponseData> = await KBApi.getFrontpage(params.hash as string);
            setData(response.data);
            setError(undefined);
        } catch (error) {
            console.error("Error fetching Frontpage", error);
            setError("Error fetching Frontpage");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFrontpage();
    }, []);
    useEffect(() => {
        if (data?.settings?.customCSS) {
            renderer.renderStatic(defaultStyles + data.settings.customCSS);
        }

        const theme = data?.settings?.theme;
        if (theme) {
            [theme?.header?.font, theme?.button?.font, theme?.text?.font].filter(Boolean).forEach((font) => {
                if (font.files) {
                    for (const variant of font.variants.map((v) => (v === "regular" ? "400" : v))) {
                        const fontStyle = variant.replace(/[0-9]*/, "");
                        const fontWeight = variant.replace(fontStyle, "");
                        const options: { fontWeight: string; fontStyle?: string } = { fontWeight };
                        if (fontStyle) {
                            options.fontStyle = fontStyle;
                        }

                        renderer.renderFont(font.family, Object.values(font.files), options);
                    }
                }
            });
        }
    }, [data]);

    return (
        <Box style={{ ...theme?.text?.style, fontFamily: theme?.text?.font?.family }}>
            <Header />
            <Row>
                <Col xs={10} xsStart={2}>
                    <Outlet />
                </Col>
            </Row>
        </Box>
    );
};
