import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { parseArticle } from "../utils/parsing";
import type { ArticleParams } from "../views/ViewArticle";

export interface Article {
    id: string;
    title: string;
    content: string;
    weight: number;
}

interface ArticleHighlightsProps {
    articles: Article[];
    params: ArticleParams;
}

export const ArticleHighlights = ({ articles, params }: ArticleHighlightsProps) => {
    const { t } = useTranslation();
    const [article] = articles;
    if (!article) {
        return null;
    }
    return (
        <div className="basis-3/4 ml-5">
            <Link to={`a/${article.id}`}>
                <h2 className="hover:underline pb-2 text-lg font-bold">{article.title}</h2>
            </Link>
            <div
                dangerouslySetInnerHTML={{
                    __html: parseArticle(article.content, {
                        id: article.id,
                        ...params,
                    }),
                }}
            />
            <Link to={`a/${article.id}`} className="kb-article-link">
                <p>{t("front_page.read_in_full")}</p>
            </Link>
        </div>
    );
};
