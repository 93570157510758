import { IStyle } from "fela";
import React from "react";
import { useFela } from "react-fela";
import { useNavigate } from "react-router";
import { Theme } from "../../../types";
import { Box } from "../box/box";
import * as styles from "./NavButton.style";

interface NavButtonProps {
    to: string;
    style?: IStyle;
    children?: React.ReactNode;
}

const NavButton: React.FC<NavButtonProps> = ({ children, to, style }) => {
    const { theme } = useFela<Theme>();
    const navigate = useNavigate();

    return (
        <Box
            as="button"
            style={{ ...styles.button(theme), ...style }}
            onClick={() => {
                navigate(to);
            }}
        >
            {children}
        </Box>
    );
};

export default NavButton;
