import { AxiosResponse } from "axios";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { KBApi } from "../api";
import { ArticleList, ShortArticle } from "../components/ArticleList";
import { Box } from "../components/generic/box/box";
import NavButton from "../components/generic/nav-button/NavButton";

interface CategoryResponse {
    id: string;
    name: string;
    icon: string;
    allArticles: ShortArticle[];
    tags: {
        id: number;
        name: string;
        articles: ShortArticle[];
    }[];
}

export const ViewCategory: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [category, setCategory] = useState<CategoryResponse | null>(null);

    useEffect(() => {
        fetchCategory();
    }, [params.id]);

    const fetchCategory = async () => {
        setIsLoading(true);

        try {
            const viewId = params.hash;
            const categoryId = params.id;
            if (!viewId || !categoryId) {
                throw new Error();
            }
            const response: AxiosResponse<CategoryResponse> = await KBApi.getCategoryById(viewId, categoryId);
            setCategory(response.data);
        } catch (err) {
            setError(t("unknown_error"));
        }

        setIsLoading(false);
    };

    if (isLoading) {
        return <p>{t("loading")}</p>;
    }

    if (error || !category) {
        return <button onClick={fetchCategory}>{t("try_again")}</button>;
    }

    const tagsWithArticles = category.tags.filter((tag) => tag.articles.length > 0);

    return (
        <div>
            <NavButton to="../">{t("go_back")}</NavButton>
            <h2 className="kb-category-header">
                {category.name} ({category.allArticles.length})
            </h2>
            {tagsWithArticles.map((tag) => (
                <>
                    <Box as="h3" style={{ fontWeigt: "bold" }}>
                        {tag.name} ({tag.articles.length})
                    </Box>
                    <ArticleList articles={tag.articles} />
                </>
            ))}
        </div>
    );
};
