import { IStyle } from "fela";
import React from "react";
import { FunctionComponent } from "react";
import { Box } from "../box/box";
import { ReactDivProps } from "../../../types";

interface IProps extends Omit<ReactDivProps, "style"> {
    style?: IStyle;
    xs?: number;
    xsStart?: number;
    sm?: number;
    smStart?: number;
    md?: number;
    mdStart?: number;
    lg?: number;
    lgStart?: number;
}

export const Col: FunctionComponent<IProps> = ({
    children,
    style = {} as IProps["style"],
    xs = 12,
    xsStart = 0,
    sm = xs,
    smStart = xsStart,
    md = sm,
    mdStart = smStart,
    lg = md,
    lgStart = mdStart,
    ...props
}) => (
    <Box
        style={{
            flexShrink: 0,
            gridColumnStart: `${xsStart}`,
            gridColumnEnd: `span ${xs}`,
            minHeight: 0,
            minWidth: 0,

            maxWidthXsMax: {
                gridColumnStart: `${xsStart}`,
                gridColumnEnd: `span ${xs}`,
            },

            maxWidthSmMax: {
                minWidthSm: {
                    gridColumnStart: `${smStart}`,
                    gridColumnEnd: `span ${sm}`,
                },
            },

            maxWidthMdMax: {
                minWidthMd: {
                    gridColumnStart: `${mdStart}`,
                    gridColumnEnd: `span ${md}`,
                },
            },

            minWidthLg: {
                gridColumnStart: `${lgStart}`,
                gridColumnEnd: `span ${lg}`,
            },
            boxSizing: "border-box",

            ...style,
        }}
        {...props}
    >
        {children}
    </Box>
);
