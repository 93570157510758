import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ViewContext } from "../context/ViewContext";
import { Box } from "./generic/box/box";
import * as headerStyles from "./Header.style";
import { Search } from "./Search";

export const Header = () => {
    const params = useParams();
    const { data } = useContext(ViewContext);
    const theme = data?.settings?.theme;

    return (
        <Box style={{ ...headerStyles.container(theme) }}>
            <Link to={`/v/${params.hash}`}>
                <Box as="h1" style={{ ...headerStyles.heading(theme) }}>
                    {data?.settings?.title || "Desk Knowledge Base"}
                </Box>
            </Link>
            <Search />
        </Box>
    );
};
