import { createRenderer } from "fela";
import webPreset from "fela-preset-web";
import pseudoClass from "fela-plugin-friendly-pseudo-class";
import prefixer from "fela-plugin-prefixer";
import namedKeysPlugin from "./fela-named-keys";
import { defaultStyles } from "../utils/constants";

export function getFelaRenderer() {
    const renderer = createRenderer({
        plugins: [...webPreset, pseudoClass(), namedKeysPlugin, prefixer()],
    });

    renderer.renderStatic(defaultStyles);

    return renderer;
}
