import axios from "axios";
import ApiConfig from "./ApiConfig";

const kbapi = axios.create();

kbapi.interceptors.request.use(
    async (config) => {
        config.baseURL = ApiConfig.getConfig().API_URL;
        return config;
    },
    (error) => Promise.reject(error),
);

export const KBApi = {
    getBaseUrl: () => {
        return kbapi.defaults.baseURL;
    },
    getSettings: (viewId: string) => {
        return kbapi.get(`/view/${viewId}/settings`);
    },
    getFrontpage: (viewId: string) => {
        return kbapi.get(`/view/${viewId}`);
    },
    getArticleById: (viewId: string, id: string) => {
        return kbapi.get(`/view/${viewId}/articles/${id}`);
    },
    getCategoryById: (viewId: string, id: string) => {
        return kbapi.get(`/view/${viewId}/categories/${id}`);
    },
    searchArticlesBy: (viewId: string, query: string) => {
        return kbapi.post(`/view/${viewId}/search?q=${query}`);
    },
};
