import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ApiConfig from "./ApiConfig";
import { App } from "./App";
import Internationalization from './i18n';

Internationalization.init();

(async () => {
    const configResult = await ApiConfig.fetchConfig();
    ApiConfig.setConfig(configResult.data);

    const root = createRoot(document.getElementById("root")!);
    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
    );
})();
