import * as React from "react";
import { ViewContext } from "../context/ViewContext";
import { Col } from "./generic/grid/col";
import { Row } from "./generic/grid/row";
import NavButton from "./generic/nav-button/NavButton";

export interface Category {
    id: number;
    name: string;
    icon: string;
    order: number;
}

interface CategoriesListProps {
    categories: Category[];
}

export const CategoriesList = (props: CategoriesListProps) => {
    const { data } = React.useContext(ViewContext);

    return (
        <Row className="kb-category-list">
            {data?.settings?.publishedCategories?.map?.((categoryId) => {
                const category = props.categories.find((c) => c.id === categoryId);
                if (!category) return null;

                const icon = category.icon || "info-circle";

                return (
                    <Col key={"CAT" + category.id} xs={3} data-name="col">
                        <NavButton
                            to={`c/${category.id}`}
                            style={{
                                width: "100%",
                                fontSize: "1.5rem",
                                padding: "16px 24px",
                                borderRadius: "10px",

                                "> i": {
                                    marginRight: "10px",
                                },
                            }}
                        >
                            <i className={"fa fa-" + icon + " mr-5"}></i>
                            {category.name}
                        </NavButton>
                    </Col>
                );
            })}
        </Row>
    );
};
