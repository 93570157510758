import { IStyle } from "fela";
import React from "react";
import { Box } from "../box/box";

interface IProps {
    steps?: number;
    gap?: number | string;
    columnGap?: number | string;
    rowGap?: number | string;
    style?: IStyle;
    [x: string]: any;
}

export const Row: React.VFC<IProps> = ({ steps = 12, gap = "16px", children, style, ...props }) => (
    <Box
        style={{
            flexShrink: 0,
            display: "grid",
            width: "100%",
            gridTemplateColumns: `repeat(${steps}, 1fr)`,
            columnGap: gap,
            rowGap: gap,
            boxSizing: "border-box",
            ...style,
        }}
        {...props}
    >
        {children}
    </Box>
);
