import { sortBy, prop } from "rambda";
import { ShortArticle } from "../components/ArticleList";

export const sortByName = sortBy(prop("name"));

export const sortByTitleWeighted = (articles: ShortArticle[]): ShortArticle[] => {
    return articles.sort((a, b) => {
        if (a.weight == b.weight) {
            return a.title.localeCompare(b.title);
        }

        return b.weight - a.weight;
    });
};
