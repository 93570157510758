import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { sortByTitleWeighted } from "src/utils/sorting";
import { Box } from "./generic/box/box";
import { ThemeContext } from "react-fela";
import { Theme } from "../types";
import { shiftColor } from "../utils/shift-color";
import { useTranslation } from "react-i18next";

export interface ShortArticle {
    id: string;
    title: string;
    parentIds?: number[];
    childIds?: number[];
    weight: number;
}

interface ArticleListProps {
    articles: ShortArticle[];
    isSearch?: boolean;
}

export const ArticleList = ({ articles, isSearch }: ArticleListProps) => {
    const theme = useContext(ThemeContext) as Theme;
    const { t } = useTranslation();

    return (
        <Box
            as="ul"
            style={{
                listStyle: "inside",
                "> a": {
                    textDecoration: "none",
                    borderBottomWidth: "1px",
                    borderBottomColor: "#e5e7eb",
                },
            }}
            className="w-full basis-1/4 mr-5"
        >
            {sortByTitleWeighted(articles).map((article) => (
                <Link to={`../a/${article.id}`}>
                    <Box
                        as="li"
                        key={`article-list-item-${article.id}`}
                        style={{
                            padding: "8px 20px",
                            ":hover": {
                                backgroundColor: shiftColor(theme?.text?.style?.backgroundColor ?? "#fff", -15),
                            },
                        }}
                        className="align-middle cursor-pointer border-b px-5 py-2 align-center hover:bg-gray-100"
                    >
                        {article.title} »{" "}
                        {isSearch && (
                            <>
                                {article.childIds?.length && (
                                    <span className="search__label">{t("search.labels.parent")}</span>
                                )}
                                {article.parentIds?.length && (
                                    <span className="search__label">{t("search.labels.child")}</span>
                                )}
                            </>
                        )}
                    </Box>
                </Link>
            ))}
        </Box>
    );
};
