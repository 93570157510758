import { IStyle } from "fela";
import { Theme } from "../types";

export const container: (theme?: Theme) => IStyle = (theme) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: theme?.header?.style?.fontSize ?? "24px",
    marginBottom: "40px",
    paddingBottom: "40px",
    paddingTop: "40px",
    tabSize: "4",
    textSizeAdjust: "100%",
    backgroundColor: theme?.header?.style?.backgroundColor ?? "initial",
    color: theme?.header?.style?.color ?? "initial",
    "> a": {
        textDecoration: "none",
    },
});

export const heading: (theme?: Theme) => IStyle = (theme) => ({
    textAlign: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "block",
    margin: "20px",
    tabSize: "4",
    textDecorationThickness: "auto",
    textSizeAdjust: "100%",
    fontFamily: theme?.header?.font?.family ?? "initial",
    fontSize: theme?.header?.style?.fontSize ?? "24px",
});
